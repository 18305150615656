$(document).ready(function () {
  var myOffset = 186;
  if ($(window).width() < 768) {
    myOffset = 100
  }
  function scroll_to(div) {
    $('html, body').animate({
      scrollTop: $(div).offset().top - myOffset
    }, 800);
  }

  setTimeout(function () {
    $('body').css('opacity', '1');
  }, 1000);

  // NAVBAR DESKTOP
  $('.navbar-brand, .nav-link').on('click', function (ev) {
    var thisHref = $(ev.currentTarget).attr('href');
    if (thisHref == "#clinica") {
      scroll_to('#clinica')
    }
    if (thisHref == "#equipa") {
      scroll_to('#equipa')
    }
    if (thisHref == "#especialidades") {
      scroll_to('#especialidades')
    }
    // if(thisHref == "#analisesClinicas") {
    //   scroll_to('#analisesClinicas')
    // }
    if (thisHref == "#galeria") {
      scroll_to('#galeria')
    }
    if (thisHref == "#acordos1") {
      scroll_to('#acordos1')
    }
    if (thisHref == "#marcacoes") {
      scroll_to('#marcacoes')
    }
    if (thisHref == "#contactos") {
      scroll_to('#contactos')
    }
  })

  // SIDEBAR MOBILE
  $('.navbar-brand, .menu-items a').on('click', function (ev) {
    var thisHref = $(ev.currentTarget).attr('href');
    if (thisHref == "#clinica") {
      scroll_to('#clinica0')
    }
    if (thisHref == "#equipa") {
      scroll_to('#equipa01')
    }
    if (thisHref == "#especialidades") {
      scroll_to('#especialidades1')
    }
    // if(thisHref == "#analisesClinicas") {
    //   scroll_to('#analisesClinicas2')
    // }
    if (thisHref == "#galeria") {
      scroll_to('#galeria')
    }
    if (thisHref == "#acordos") {
      scroll_to('#acordos1')
    }
    if (thisHref == "#marcacoes") {
      scroll_to('#marcacoes')
    }
    if (thisHref == "#contactos") {
      scroll_to('#contactos')
    }
  })

  $(".whole-handler .mylight-toggler, .m-head").bind("click touch", function (e) {
    if ($("body").hasClass('sidebar-visible')) {
      this.css3d = !0; var t = 1 == this.css3d ? "translate3d(0, 0,0)" : "translate(0, 0)";
      $(".page-sidebar").css({ transform: t }), $("body").removeClass("sidebar-visible")
    } else {
      this.css3d = !0; var t = 1 == this.css3d ? "translate3d(250px, 0,0)" : "translate(250px, 0)";
      $(".page-sidebar").css({ transform: t }), $("body").addClass("sidebar-visible")
    }
  })

  // Get started!
  $('#clinica').css('height', $(window).height() + 15 + 'px') // Clinica Desktop
  $('#clinica0').css('height', ($(window).height() - myOffset) + 100 + 'px'); // Clinica Mobile

  $('#equipa').css('height', ($(window).height() - myOffset) + 15 + 'px'); // Equipa Medica Desktop
  $('#equipa01').css('height', ($(window).height() - myOffset) * 1.4 + 'px'); // Equipa Medica Mobile

  $('#especialidades').css('height', ($(window).height() - myOffset) + 15 + 'px'); // Especialidades Desktop
  $('#especialidades1').css('height', ($(window).height() - myOffset) + 'px'); // Especialidades Mobile

  // $('#galeria').css('height', ($(window).height() - myOffset)+15 + 'px'); // Galeria Desktop

  // $('#analisesClinicas').css('height', ($(window).height() - myOffset) + 'px'); // Analises Clinicas Desktop
  // $('#analisesClinicas2').css('height', ($(window).height() - myOffset)*1.2 + 'px'); // Analises Clinicas Mobile

  $('.md-container').css('height', ($(window).height() - myOffset) / 2 + 'px'); // Container

  // Clinica Desktop
  $('#clinica').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
  });

  // Clinica Mobile 
  $('#clinica0').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
  });



  $('#equipa').on('afterChange', function (event, slick, currentSlide) {
    if (currentSlide == 0) {
      $('#equipa').find(".slick-prev").removeClass("myslick-prev");
      $('#equipa').find(".slick-next").removeClass("myslick-next");
    } else {
      $('#equipa').find(".slick-prev").addClass("myslick-prev");
      $('#equipa').find(".slick-next").addClass("myslick-next");
    }
  });

  // Especialidades Desktop
  $('#especialidades').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
  });

  // Especialidades Mobile
  $('#especialidades1').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
  });

  // Analises Clinicas Desktop
  // $('#analisesClinicas').slick({
  //   dots: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 1,
  // });

  // // Analises Clinicas Mobile
  // $('#analisesClinicas2').slick({
  //   dots: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 1,
  // });

  // Acordos
  $('#acordos1').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 700,
  });

  // Galeria
  $('#galeria').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    mobileFirst: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          fade: true,
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          fade: true,
        }
      }
    ]
  });

  $('#galeria').slickLightbox({
    itemSelector: 'a',
    navigateByKeyboard: true
  });

  $('.slick-lightbox-close').on('click', function (ev) {
    ev.stopPropagation()
  })

  flatpickr('#datepicker', {
    dateFormat: 'd.m.Y',
    //maxDate: '01.01.2020',
    minDate: 'today',
    "locale": 'pt'
    //maxDate: new Date().fp_incr(15)
  });

  flatpickr('#datepicker-birth', {
    dateFormat: 'd.m.Y',
    maxDate: 'today',
    "locale": 'pt'
    //maxDate: new Date().fp_incr(15)
  });

  function isEven(n) {
    return n % 2 == 0;
  }

  $.ajax({
    type: "GET",
    url: 'https://api.whitebrim.co/api/model/5e1dd3e2f541a319f982868b/equipa',
    contentType: 'application/json',
    dataType: 'json',
    statusCode: {
      200: function (doctors) {
        let paresArray = [];
        let imparesArray = [];
        doctors.results.sort(function (a, b) {
          return a.ordering.global - b.ordering.global;
        });
        doctors.results.forEach(function (doctor, index) {
          if (isEven(index)) {
            if(doctor.imagem.url !== ""){
              paresArray.push(`<div class="row md-row" style="height : 50%;overflow:hidden;">
              <div class="offset-sm-1 col-sm-3 md-container">
                <img
                  src="${doctor.imagem.url}"
                  alt="staff img"
                  style="width : 100%;position:relative;z-index:-1"
                />
              </div>
              <div class="col-sm-8 md-section">
                <h3>${doctor.name}</h3>
                <h5>${doctor.especialidade}</h5>
                ${doctor.descricao.en_EN}
              </div>
            </div>`)
            } else {
              paresArray.push(`<div class="row md-row" style="height : 50%;overflow:hidden;">
              <div class="offset-sm-1 col-sm-3 md-container">
                <img
                  src="/assets/img/icon_female.png"
                  alt="staff img"
                  style="width : 100%;position:relative;z-index:-1"
                />
              </div>
              <div class="col-sm-8 md-section">
                <h3>${doctor.name}</h3>
                <h5>${doctor.especialidade}</h5>
                ${doctor.descricao.en_EN}
              </div>
            </div>`)
            }
          } else {
            if(doctor.imagem.url !== ""){
            imparesArray.push(`<div class="row" style="height : 50%;">
              <div class="offset-sm-1 col-sm-3 md-container">
                <img
                  src="${doctor.imagem.url}"
                  alt="staff img"
                  style="width : 100%;"
                />
              </div>
              <div class="col-sm-8 md-section">
                <h3>${doctor.name}</h3>
                <h5>${doctor.especialidade}</h5>
                ${doctor.descricao.en_EN}
              </div>
            </div>`)
            } else {
              imparesArray.push(`<div class="row" style="height : 50%;">
              <div class="offset-sm-1 col-sm-3 md-container">
                <img
                  src="/assets/img/icon_female.png"
                  alt="staff img"
                  style="width : 100%;"
                />
              </div>
              <div class="col-sm-8 md-section">
                <h3>${doctor.name}</h3>
                <h5>${doctor.especialidade}</h5>
                ${doctor.descricao.en_EN}
              </div>
            </div>`)
            }
          }
        });

        let thisRowNode = $('#equipa');
        paresArray.forEach((leRow, index2) => {
          let newSlideNode = $('<div class="slide" />');
          newSlideNode.append(paresArray[index2]);
          if (index2 !== (paresArray.length - 1) || imparesArray.length >= paresArray.length) newSlideNode.append(imparesArray[index2]);
          thisRowNode.append(newSlideNode)
        });

        // Equipa Medica Desktop
        $('#equipa').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          initialSlide: 0,
        });
      },
    }
  });

  $.ajax({
    type: "GET",
    url: 'https://api.whitebrim.co/api/model/5e1dd3e2f541a319f982868b/equipa',
    contentType: 'application/json',
    dataType: 'json',
    statusCode: {
      200: function (doctors) {
        doctors.results.sort(function (a, b) {
          return a.ordering.global - b.ordering.global;
        });
        doctors.results.forEach(function (doctor, index) {
          if(doctor.imagem.url !== "") {
            $('#equipa01').append(`
              <div class="slide" id="slide012" >
                  <div class="row">
                    <div class="offset-sm-1 col-sm-3 md-container" 
                      style="height : 300px;"
                    >
                      <img
                        src="${doctor.imagem.url}"
                        alt="staff img"
                        style="width : 100%;position:relative;z-index:-1"
                      />
                    </div>
                    <div class="col-sm-8 col-md-6 md-section">
                      <h3>${doctor.name}</h3>
                      <h5>${doctor.especialidade}</h5>
                      ${doctor.descricao.en_EN}
                    </div>
                  </div>
              </div>
            `)
          } else {
            $('#equipa01').append(`
              <div class="slide" id="slide012" >
                  <div class="row">
                    <div class="offset-sm-1 col-sm-3 md-container" 
                      style="height : 300px;"
                    >
                      <img
                        src="/assets/img/icon_female.png"
                        alt="staff img"
                        style="width : 100%;position:relative;z-index:-1"
                      />
                    </div>
                    <div class="col-sm-8 col-md-6 md-section">
                      <h3>${doctor.name}</h3>
                      <h5>${doctor.especialidade}</h5>
                      ${doctor.descricao.en_EN}
                    </div>
                  </div>
              </div>
            `)
          }
        });
        // Equipa Medica Mobile
        $('#equipa01').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
        });
      },
    }
  });

  //! MARCAÇÕES
  $('#form').validator({
    focus: 100
  }).on('submit', function (e) {
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
    } else {
      e.preventDefault();
      var formOptions = {
        email: $("#form").find('[name="email"]').val(),
        name: $("#form").find('[name="nome"]').val() + $("#form").find('[name="apelido"]').val(),
        text: "<br><br>" + "Especialidade : " + $("#form").find('[name="especialidade"]').val() + "<br><br>Data : " + $("#form").find('[name="datepicker"]').val() + "<br><br>Horario : " + $("#form").find('[name="horario"]').val() + "<br><br>Telefone : " + $("#form").find('[name="telefone"]').val() + "<br><br>Seguro de Saúde: " + $("#form").find('[name="seguro"]').val() + "<br><br>" + $("#form").find('[name="motivo"]').val() + "<br><br>",
      }
      var formData = new FormData();
      formData.append('name', formOptions.name);
      formData.append('email', formOptions.email);
      formData.append('text', formOptions.text);
      var request = new XMLHttpRequest();
      request.open("POST", `https://api.whitebrim.co/api/project/5e1dd3e2f541a319f982868b/account/email`);
      request.send(formData);
      var $tmpBtn = $('#submit');
      var oldHtml = $tmpBtn.val()
      $('#submit').text("A enviar...").attr("disabled", true)
      console.log(request)
      request.onreadystatechange = function () {
        if (request.readyState == 4 && (request.status == 200 || request.status == 201)) {
          $tmpBtn.text("Enviado");
          setTimeout(function () {
            $tmpBtn.val(oldHtml).attr('disabled', false);
            $('[name="especialidade"]').val('')
            $('[name="datepicker"]').val('')
            $('[name="horario"]').val('')
            $('[name="motivo"]').val('')
            $('[name="nome"]').val('')
            $('[name="apelido"]').val('')
            $('[name="telefone"]').val('')
            $('[name="email"]').val('')
            $('[name="seguro"]').val('')
          }, 10000)
        }
      }
    }
  })
});
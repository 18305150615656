/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable newline-after-var */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable-next-line vars-on-top */
import _ from 'lodash';
import { getCountryByCode } from 'countryList';

const Whitebrim = class {
  constructor(options) {

    this.VERSION = '1.3.4';
    this.options = _.extend({}, _.result(this, 'options'), options);
    this.cart = {
      items: []
    };
    this.guestCart = {
      items: []
    };
    this.user = {};
    if (localStorage.getItem('wb_token') == null || localStorage.getItem('wb_token') === 'null') {
      localStorage.removeItem('wb_token');
    }
    this.live_token = localStorage.getItem('wb_token');

    if (this.options.matomo_tracker_id) {
      var target = document.querySelector('body');
      var div = document.createElement('script');
      div.innerHTML = `
        var _paq = window._paq || [];
        _paq.push(['trackPageView']); 
        _paq.push(['enableLinkTracking']);
        (function() {
          var u=\"//piwik.whitebrim.co/\";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', ${this.options.matomo_tracker_id}]);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      `;
      target.parentNode.insertBefore(div, target);
      target.parentNode.insertBefore(div, target.nextSibling);
    }
  }
  processRequest = (options, method, url, requiresAuth, callback) => {
    const xhr = new XMLHttpRequest();

    if (requiresAuth) {
      if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
      else {
        // *do not has live_token where it should
      }
    }

    xhr.open(method, url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = () => {
      callback(xhr, this);
    };
    xhr.send(JSON.stringify(options));
  };

  get_api_key = () => {
    return this.options.API_KEY;
  };
  get_domain = () => {
    return this.options.DOMAIN;
  };
  get_prefix = () => {
    return this.options.PREFIX;
  };
  getProjectId = () => {
    return this.options.PROJECT_ID;
  };

  getItems = (options, callback) => {
    let xhr = new XMLHttpRequest();
    let filterString = '?';

    if (options.filters) {
      let thisLength = Object.keys(options.filters).length;

      let i = 0;

      for (var k in options.filters) {
        if (options.filters[k] !== null) filterString += `${k}=${options.filters[k]}`;
        if (i < thisLength - 1 || options.pagination) filterString += '&';
        i++;
      }
    }
    if (options.pagination) filterString += `&limit=${options.pagination.limit}&page=${options.pagination.page}&`;

    xhr.open('GET', `${this.options.DOMAIN}/api/model/${this.options.PROJECT_ID}/${options.modelname}${filterString}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      const parsedResponse = JSON.parse(xhr.responseText);

      if (xhr.status === 200) {
        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        callback(true, parsedResponse);
      }
    };
    xhr.send(JSON.stringify());
  };

  loginUser = (options, callback) => {
    let that = this;

    this.processRequest({
      email: options.email,
      password: options.password
    },
    'POST',
    `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/login`,
    false,
    (xhr, response) => {
      if (xhr.status === 200) {
        let parsedResponse = JSON.parse(xhr.responseText);

        localStorage.setItem('wb_token', xhr.getResponseHeader('authorization'));
        localStorage.setItem('wb_token_expiration', parsedResponse.valid_until);

        that.live_token = xhr.getResponseHeader('authorization');
        callback(false, parsedResponse);
      } else if (xhr.status === 401) {
        callback(true, null);
      } else {
        callback(true, null);
      };
    });
  };

  getUser = (callback) => {
    if (!this.live_token) {
      return callback(true, {});
    }
    let xhr = new XMLHttpRequest();

    xhr.open('GET', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/me`);
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const parsedResponse = JSON.parse(xhr.responseText);
        parsedResponse.cart = _.filter(parsedResponse.cart, (el) => {
          return (el !== null);
        });
        callback(false, parsedResponse);
      } else if (xhr.status === 401) {
        localStorage.removeItem('wb_token');
        location.reload();
        callback(true, {});
      } else if (xhr.status === 403) {
        // *Token Expired Error
        // *Remove the token if exists
        window.localStorage.removeItem('wb_token');
        location.reload();
      } else if (xhr.status === 404) {
        // *could not find the user
        // *and we have a token, so user is probably invalid
        window.localStorage.removeItem('wb_token');
        location.reload();
      } else if (xhr.status === 400) {
        window.localStorage.removeItem('wb_token');
        location.reload();
      }
    };
    xhr.send();
  };
  setUser = (options) => {
    this.user.shipping_address = options.shipping_address;
    this.user_id = options._id;
    this.cart.items = options.cart;
  };

  registerUser = (options, callback) => {
    var that = this;

    this.processRequest({
      name: options.name,
      email: options.email,
      password: options.password,
      shipping_address: {
        name: options.shipping_address.name,
        street1: options.shipping_address.street1,
        street2: options.shipping_address.street2,
        code: options.shipping_address.code,
        city: options.shipping_address.city,
        country: options.shipping_address.country
      },
      billing_address: {
        name: options.shipping_address.name,
        street1: options.shipping_address.street1,
        street2: options.shipping_address.street2,
        code: options.shipping_address.code,
        city: options.shipping_address.city,
        country: options.shipping_address.country
      },
      custom: options.custom
    },
    'POST',
    `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account`,
    false,
    (xhr, response) => {
      const parsedResponse = JSON.parse(xhr.responseText);

      if (xhr.status === 201) {
        localStorage.setItem('wb_token', xhr.getResponseHeader('authorization'));
        localStorage.setItem('wb_token_expiration', parsedResponse.valid_until);
        that.live_token = xhr.getResponseHeader('authorization');
        callback(false, {});
      } else if (xhr.status !== 200) {
        callback(true, xhr);
      }
    });
  };

  submitResetPassword = (options, callback) => {
    let xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/me/complete_password_change`);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function () {
      const parsedResponse = JSON.parse(this.responseText);

      if (xhr.status === 200) {
        callback(false, parsedResponse);
      } else if (xhr.status !== 200) {
        callback(true, xhr);
      }
    };
    xhr.send(JSON.stringify({
      password: options.password,
      email: options.email,
      user_ref: options.user_ref,
      query_ref: options.query_ref
    }));
  };

  submitRecoverPassword = (options, callback) => {
    let xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/me/request_password_change`);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function () {
      if (xhr.status === 200) {
        callback(false);
      } else if (xhr.status !== 200) {
        callback(true);
      }
    };
    xhr.send(JSON.stringify({
      email: options.email
    }));
  };
  editUser = (options, callback) => {
    var xhr = new XMLHttpRequest();

    xhr.open('PUT', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/me`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      const parsedResponse = JSON.parse(this.responseText);

      if (xhr.status === 200) {
        callback(false, parsedResponse);
      } else if (xhr.status !== 200) {
        callback(true, xhr);
      }
    };

    const baseForm = {
      name: options.name,
      email: options.email,
      // eslint-disable-next-line camelcase
      shipping_address: {
        name: options.shipping_address.name,
        street1: options.shipping_address.street1,
        street2: options.shipping_address.street2,
        code: options.shipping_address.code,
        city: options.shipping_address.city,
        country: options.shipping_address.country
      },
      billing_address: {
        name: options.billing_address.name,
        street1: options.billing_address.street1,
        street2: options.billing_address.street2,
        code: options.billing_address.code,
        city: options.billing_address.city,
        country: options.billing_address.country
      }
    };

    if (options.custom) {
      baseForm.custom = options.custom;
    }

    xhr.send(JSON.stringify(baseForm));
  };

  changePassword = (options, callback) => {
    var xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/me/change_password`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 200) {
        console.log(xhr);
        callback(false, {});
      } else if (xhr.status !== 200) {
        console.log(xhr);
        callback(true, xhr);
      }
    };

    const baseForm = {
      email: options.email,
      password: options.password,
      new_password: options.new_password
    };

    xhr.send(JSON.stringify(baseForm));
  };

  isSessionLogged = (options, callback) => {
    if (this.live_token) {
      return true;
    };
    return false;
  };

  getCart = (callback) => {
    return callback(null, this.cart);
  };

  getStoreSettings = (callback) => {
    var that = this;
    let xhr = new XMLHttpRequest();

    xhr.open('GET', `${this.options.DOMAIN}/api/store/${this.options.STORE_ID}/frontstore`);

    xhr.onload = function () {

      if (xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);

        that.shipping_rates = parsedResponse.shipping_rates;
        that.shipping_rules = parsedResponse.shipping_rules;
        that.vat = parsedResponse.VAT;
        if (that.shipping_rules.rotw) {
          // eslint-disable-next-line no-use-before-define
          let myFullCountries = getCountryByCode(null, true);
          let i = 0;

          for (let key in myFullCountries) {
            parsedResponse.shipping_rules.allow_list[i] = { name: myFullCountries[key], code: key };
            i++;
          }
        } else {
          for (let i = 0; i < parsedResponse.shipping_rules.allow_list.length ; i++) {
            parsedResponse.shipping_rules.allow_list[i] = {
              // eslint-disable-next-line no-use-before-define
              name: getCountryByCode(parsedResponse.shipping_rules.allow_list[i]),
              code: parsedResponse.shipping_rules.allow_list[i]
            };
          }
        }
        callback(false, parsedResponse);
      } else if (xhr.status === 401) {
        callback(true, {});
      } else if (xhr.status === 403) {

      } else if (xhr.status === 404) {

      } else if (xhr.status === 500) {

      }
    };
    xhr.send();
  }

  getShippingFee = (options, callback) => {
    // *Only supporting flat right now

    const returnObj = [];

    for (var i = 0; i < this.shipping_rates.flat.length; i++) {

      for (var j = 0; j < this.shipping_rates.flat[i].country_code.length; j++) {
        // matching the country code with available country rules
        if (this.shipping_rates.flat[i].country_code[j] === options.country_code) {
          // calculate the total amounts
          var myTotalItems = 0;
          var myTotalAmount = 0;
          for (var z = 0; z < this.cart.items.length; z++) {
            myTotalItems += this.cart.items[z].quantity;
            myTotalAmount += (this.cart.items[z].quantity * this.cart.items[z].price);
          }

          if (this.shipping_rates.flat[i].free.enabled && this.shipping_rates.flat[i].free.minimum <= myTotalAmount) {
            this.shipping_ref = this.shipping_rates.flat[i]. id;
            return { totalFee: 0 };
          // eslint-disable-next-line no-else-return
          } else {
            returnObj.push({
              id: this.shipping_rates.flat[i]._id,
              name: this.shipping_rates.flat[i].name,
              totalFee: (myTotalItems * this.shipping_rates.flat[i].per_item) + this.shipping_rates.flat[i].per_order
            });
          }
        }
      }
      if (returnObj.length === 0 && this.shipping_rules.rotw) {

        let isSomeROTW = this.shipping_rates.flat[i].country_code.some(function (item) {
          return item === 'ROTW';
        });
        if (isSomeROTW) {
          // eslint-disable-next-line no-redeclare
          for (var z = 0; z < this.cart.items.length; z++) {
            myTotalItems += this.cart.items[z].quantity;
            myTotalAmount += (this.cart.items[z].quantity * this.cart.items[z].price);
          }

          if (this.shipping_rates.flat[i].free.enabled && this.shipping_rates.flat[i].free.minimum <= myTotalAmount) {
            this.shipping_ref = this.shipping_rates.flat[i]._id;
            return { totalFee: 0 };
          // eslint-disable-next-line no-else-return
          } else {
            returnObj.push({
              id: this.shipping_rates.flat[i]._id,
              name: this.shipping_rates.flat[i].name,
              totalFee: (myTotalItems * this.shipping_rates.flat[i].per_item) + this.shipping_rates.flat[i].per_order
            });
          }
        }
      }
    }

    if (this.shipping_rates.store_pickup.enabled) {
      returnObj.push({
        id: 'store_pickup',
        name: this.shipping_rates.store_pickup.name,
        totalFee: (myTotalItems * this.shipping_rates.store_pickup.per_item) + this.shipping_rates.store_pickup.per_order
      });
    }

    return returnObj;
  };

  preparePaypal = (options, callback) => {
    var xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/place_order`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);

        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        const parsedResponse = JSON.parse(this.responseText);

        callback(true, parsedResponse);
      }
    };
    const myTokenizer = {};

    myTokenizer['paypal_data'] = {
      type: 'sale:prepare_payment'
    };
    myTokenizer['shipping_ref'] = options.shipping_ref;
    xhr.send(JSON.stringify(myTokenizer));
  };
  updatePaypal = (options, callback) => {
    var xhr = new XMLHttpRequest();

    xhr.open('PUT', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/update_order/${options.paypal_data.orderID}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);

        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        const parsedResponse = JSON.parse(this.responseText);

        callback(true, parsedResponse);
      }
    };
    const myTokenizer = {};

    myTokenizer['paypal_data'] = {
      type: options.paypal_data.type,
      orderID: options.paypal_data.orderID,
      payerID: options.paypal_data.payerID
    };
    xhr.send(JSON.stringify(myTokenizer));
  };

  submitOrder = (options, callback) => {
    var xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/place_order`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);

        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        const parsedResponse = JSON.parse(this.responseText);

        callback(true, parsedResponse);
      }
    };
    const myTokenizer = {};
    if (typeof options.extraData !== 'undefined' && options.extraData !== null) {
      myTokenizer.extraData = options.extraData;
    }

    if (options.payment_method === 'stripe') {
      myTokenizer['stripe_data'] = {
        // 'token' : options.token,
        type: 'sale:cc',
        intent: options.intent
      };

    } else if (options.payment_method === 'paypal') {
      myTokenizer['paypal_data'] = {
        token: options.token,
        type: 'sale'
      };
    } else if (options.payment_method === 'offline') {
      myTokenizer['offline_data'] = {
        token: null
      };
      myTokenizer['shipping_ref'] = options.shipping_ref;
    } else if (options.payment_method === 'multibanco') {
      myTokenizer['stripe_data'] = {
        type: 'sale:mb',
        return_url: options.return_url
      };
      myTokenizer['shipping_ref'] = options.shipping_ref;
    } else if (options.payment_method === 'crowdfund') {
      myTokenizer['crowdfund_data'] = {
        intent: options.intent
      };
    } else {
      return callback(true, { 'message': 'Invalid or unexistent payment method' });
    }
    xhr.send(JSON.stringify(myTokenizer));
  };

  createPaymentIntent = (options, callback) => {
    let xhr = new XMLHttpRequest();

    xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/payment_intent`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);

    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);

        callback(false, parsedResponse);
      } else if (xhr.status !== 200 && xhr.status !== 201) {
        callback(true, xhr);
      }
    };
    xhr.send(JSON.stringify({
      platform: 'stripe',
      shippingRef: options.shippingRef
    }));
  };

  updatePaymentIntent = (options, callback) => {
    let xhr = new XMLHttpRequest();

    xhr.open('PUT', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/payment_intent`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        const parsedResponse = JSON.parse(this.responseText);
        callback(false, parsedResponse);
      } else if (xhr.status !== 200 && xhr.status !== 201) {
        callback(true, xhr);
      }
    };
    xhr.send(JSON.stringify({
      platform: 'stripe',
      shippingRef: options.shippingRef,
      clientSecret: options.clientSecret
    }));
  };

  createPaymentIntentCf = (options, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', `${this.options.DOMAIN}/api/store/${this.options.STORE_ID}/account/payment_intent`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        let parsedResponse = JSON.parse(this.responseText);
        callback(false, parsedResponse);
      } else if (xhr.status !== 200 && xhr.status !== 201) {
        callback(true, xhr);
      }
    };
    xhr.send(JSON.stringify({
      platform: 'stripe',
      intent: options.intent
    }));
  }

  updatePaymentIntentCf = (options, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.open('PUT', `${this.options.DOMAIN}/api/store/${this.options.STORE_ID}/account/payment_intent`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      if (xhr.status === 201 || xhr.status === 200) {
        let parsedResponse = JSON.parse(this.responseText);
        callback(false, parsedResponse);
      } else if (xhr.status === 410) {
        let parsedResponse = JSON.parse(this.responseText);
        callback(true, parsedResponse);
      } else if (xhr.status !== 200 && xhr.status !== 201) {
        callback(true, xhr);
      }
    };
    xhr.send(JSON.stringify({
      platform: 'stripe',
      intent: options.intent,
      pi_id: options.pi_id
    }));
  };

  addToCart = (options, callback) => {
    // eslint-disable-next-line no-unused-vars
    const currentCartItem = null;
    // const foundInCollection = false;

    for (var i = 0; i < this.cart.items.length; i++) {
      let objectsAreSame = false;

      if (this.cart.items[i].variant && options.variant && this.cart.items[i].quantity !== 0 && this.cart.items[i].variant.variant_id === options.variant.variant_id) {
        objectsAreSame = true;
      }
      if (this.cart.items[i].object_id === options.object_id && objectsAreSame) {
        this.cart.items[i].quantity += options.quantity;
        // foundInCollection = true;
        currentCartItem = this.cart.items[i];
      }
    }

    if (!this.isSessionLogged()) {
      // * should not be unlogged to be able to add to a cart. Yet

    } else {
      let myOptionalId = '';

      if (options.item_id) myOptionalId = '&item_id=' + options.item_id;
      let xhr = new XMLHttpRequest();

      xhr.open('POST', `${this.options.DOMAIN}/api/project/${this.options.PROJECT_ID}/account/${this.user_id}/add_to_cart?${myOptionalId}`);
      xhr.setRequestHeader('Content-Type', 'application/json');
      if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
      xhr.onload = function () {
        if (xhr.status === 201 || xhr.status === 200) {
          const parsedResponse = JSON.parse(this.responseText);
          _.each(parsedResponse.cart, (elResult) => {
            if (elResult.discount && elResult.discount.active) {
              elResult.price = elResult.discount.finalPrice;
            }
          });
          callback(false, parsedResponse);
        } else if (xhr.status !== 200 && xhr.status !== 201) {
          callback(true, xhr);
        }
      };
      xhr.send(JSON.stringify({
        cart_item: {
          model_id: options.model_id,
          model_name: options.model_name,
          quantity: options.quantity,
          variant: options.variant,
          addons: options.addons,
          customizations: options.customizations
        }
      }));
    }
  };
  getItemByUri = (options, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', `${this.options.DOMAIN}/api/model/${this.options.PROJECT_ID}/${options.modelname}/${options.modeluri}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);
    xhr.onload = function () {
      const parsedResponse = JSON.parse(this.responseText);

      if (xhr.status === 200) {
        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        callback(true, parsedResponse);
      }
    };
    xhr.send(JSON.stringify());
  };
  getCrowdfundPage = (options, callback) => {
    let xhr = new XMLHttpRequest();

    xhr.open('GET', `${this.options.DOMAIN}/api/store/${this.options.STORE_ID}/cf_page/${options.url}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (this.live_token) xhr.setRequestHeader('Authorization', this.live_token);

    xhr.onload = function () {
      const parsedResponse = JSON.parse(this.responseText);

      if (xhr.status === 200) {
        return callback(null, parsedResponse);
      } else if (xhr.status !== 401) {
        callback(true, parsedResponse);
      }
    };
    xhr.send(JSON.stringify());
  };
};

Whitebrim.prototype.get = (param) => {
  const rtnValue = null;
  switch (param) {
    case 'apiKey':
      rtnValue = this.options.API_KEY;
      break;
  };
  return rtnValue;
};
export default {Whitebrim};
